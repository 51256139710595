import React from 'react'
import t from '../../i18n'
import IconEmail from '../../../static/images/icon-email.svg'
import IconFax from '../../../static/images/icon-fax.svg'
import IconTelephone from '../../../static/images/icon-telephone.svg'
import IconMarker from '../../../static/images/icon-marker.svg'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'

const BlockContact = ({locale})  => {

    // Define translations
    const translations = t( 'Contact', locale )

    // Define site metadata
    const siteMeta = useSiteMetadata()

    return (
        <section className='statistics'>
            <h2 className='statistics__title'>{ translations.title }</h2>
            <span className='statistics__subtitle'>{ translations.description }</span>
            <ul className='statistics__list'>
                <li className='statistics__list-item'>
                    <div className='statistics__number'><IconTelephone/></div>
                    <div className='statistics__info'>
                        <h5 className='bold'>{ translations.address }</h5>
                        <p>{ siteMeta.address }<br/>{ siteMeta.postal }<br/>{ siteMeta.location }</p>
                    </div>
                </li>
                <li className='statistics__list-item'>
                    <div className='statistics__number'><IconMarker/></div>
                    <div className='statistics__info'>
                        <h5 className='bold'>{ translations.telephone }</h5>
                        <a href={`tel:${siteMeta.telephone }`}>{siteMeta.telephone }</a>
                    </div>
                </li>
                <li className='statistics__list-item'>
                    <div className='statistics__number'><IconFax/></div>
                    <div className='statistics__info'>
                        <h5 className='bold'>{ translations.fax }</h5>
                        <p>{ siteMeta.fax }</p>
                    </div>
                </li>
                <li className='statistics__list-item'>
                    <div className='statistics__number'><IconEmail/></div>
                    <div className='statistics__info'>
                        <h5 className='bold'>{ translations.email }</h5>
                        <a href={`mailto:${ siteMeta.email }`}>{ siteMeta.email }</a>
                    </div>
                </li>
            </ul>
        </section>
    )
}

export default BlockContact

