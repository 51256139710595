import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { linkToId } from '../../assets/js/utils'
import LinesEllipsis from 'react-lines-ellipsis'
import ChevronRight from '../../../static/images/chevron-right.svg'
import './blogpost.scss'

const BlogPost = ({
    item
})  => {

    // Add fluid images
    const newsImage = item.node.pageImage && item.node.pageImage.fluid ? <Img fluid={item.node.pageImage.fluid} /> : ''

    // Show correct date
    const date = new Date(item.node.createdAt)
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear()

    return (
        <Link to={linkToId(item.node.id)} className='blogpost'>
            { item.node.pageImage !== null ?
                <header className='blogpost__header'>
                    { newsImage }
                </header>
                :
                <header className='blogpost__placeholder'/>
            }
            <section className="blogpost__content">
                <h4 className='blogpost__title'>{item.node.pageTitle}</h4>
                { item.node.pageIntroduction !== null &&
                    <LinesEllipsis
                        text={item.node.pageIntroduction.internal.content}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                    />
                }
                <span className="blogpost__date">{day} {month} {year}</span>
            </section>
            <footer className="blogpost__footer">
                <span className="blogpost__link"><ChevronRight/></span>
            </footer>
        </Link>
    )

}

export default BlogPost

