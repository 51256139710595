import React from 'react'
// import { window } from 'browser-monads'
import { Link } from 'gatsby'
import t from '../../i18n'
import RichText from '../richText'
import BlockStatistiscs from './blockStatistiscs'
import BlockContact from './blockContact'
import ChevronLeft from '../../../static/images/chevron-left.svg'
import './pageheader.scss'
import './block.scss';

const PageHeader = ( props )  => {

    // Define type & parent URL
    // const parentUrl = window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1)

    // Define translations
    const translations = t( 'Pagehead', props.locale )
    const translationsForm = t( 'Form', props.locale )

    // console.log(props.locale)

    return (
        <section className={props.page.pageVariant ? 'page-head page-header--variant' : 'page-head'}>
            <div className='page-header__grid'>
                <div className="page-header page-header--bg-image" style={ { backgroundImage: props.page.pageImage ? `url(${props.page.pageImage.fluid.src})` : 'none'} }>
                    <div className='container'>
                        <div className="page-header__flex">
                            <div className='page-header__inner'>
                                <Link to='/' className='page-header__link bold'><ChevronLeft/> { translations.goBack }</Link>
                                { props.page.pageTitle &&
                                    <h1 className='page-header__title'>
                                        <span>{props.page.pageTitle}</span>
                                        {props.page.pageSubtitle}
                                    </h1>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/*Show contact block*/}
                {props.page.pageType && props.page.pageType[0] === 'contact' &&
                    <div className="page-statistics">
                        <div className='container'>
                            <BlockContact locale={props.locale}/>
                        </div>
                    </div>
                }

                {/*Show statistics block*/}
                {props.page.pageStatistics &&
                <div className="page-statistics">
                    <div className='container'>
                        <BlockStatistiscs statistics={props.page.pageStatistics}/>
                    </div>
                </div>
                }

                {/*Show page description*/}
                {props.page.pageDescription &&
                    <div className="page-introduction">
                        <div className="container padding">
                            <div className="introduction">
                                <RichText data={props.page.pageDescription} />
                                {props.page.pageType && props.page.pageType[0] === 'contact' &&
                                    <section className="form padding-bottom">
                                        <form method="post"
                                              action={`/${props.locale.toLowerCase()}/success`}
                                              name="contact"
                                              data-netlify="true"
                                              data-netlify-honeypot="bot-field">
                                            <input type="hidden" name="bot-field" />
                                            <input type="hidden" name="form-name" value="contact" />

                                            <div className="form__item">
                                                <label htmlFor="bedrijf">{ translationsForm.bedrijfsnaam }</label>
                                                <input type="text" name="bedrijf" id="bedrijf" placeholder={ translationsForm.bedrijfsnaam } required />
                                            </div>

                                            <div className="form__item">
                                                <label htmlFor="emailadres">{ translationsForm.email }</label>
                                                <input type="email" name="emailadres" id="emailadres" placeholder={ translationsForm.email } required />
                                            </div>

                                            <div className="form__item">
                                                <label htmlFor="telefoon">{ translationsForm.telephone }</label>
                                                <input type="text" name="telefoon" id="telefoon" placeholder={ translationsForm.telephone } />
                                            </div>

                                            <input type="submit" value={ translationsForm.contact } className="button button--primary button--icon" />
                                        </form>
                                    </section>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default PageHeader
