import React from "react"
import chunk from 'lodash/chunk'
import BlogPost from '../../components/blogpost'
import './pagination.scss'
import t from '../../i18n'

class ItemLoop extends React.Component {

    constructor() {
        super()
        let postsToShow = 8
        let postsToIncrease = 8
        let postsEnd = false

        this.state = {
            showingMore: postsToShow > postsToIncrease,
            postsToShow,
            postsToIncrease,
            postsEnd
        }
    }

    render() {
        // Destructure
        let data = this.props.data.edges;

        // Define translations
        const translations = t( 'Buttons', this.props.locale )

        // If amount of posts is smaller than default hide load more.
        if (this.state.postsToShow >= data.length) {
            this.setState.postsEnd = true
        }

        return (
            <>
                <div className='grid--four'>
                    {chunk(data.slice(0, this.state.postsToShow), 2).map((chunk) => (
                        chunk.map((item, i) => (
                            <BlogPost key={i} item={item}/>
                        ))
                    ))}
                </div>
                {!this.state.postsEnd && (
                    <div className='pagination'>
                        <button
                            className='button button--primary'
                            onClick={() => {
                                this.setState({
                                    postsToShow: this.state.postsToShow + this.state.postsToIncrease,
                                    showingMore: true,
                                    postsEnd: this.state.postsToShow + this.state.postsToIncrease >= data.length
                                })
                            }}
                        >{ translations.loadMore}</button>
                    </div>
                )}
            </>
        )
    }
}

export default ItemLoop
