import React from 'react'

const BlockStatistiscs = (props)  => {

    return (
        <section className='statistics'>
            { props.statistics.statsGlobalTitle &&
                <h2 className='statistics__title'>{props.statistics.statsGlobalTitle}</h2>
            }
            { props.statistics.statsGlobalSubtitle &&
                <span className='statistics__subtitle'>{props.statistics.statsGlobalSubtitle}</span>
            }
            <ul className='statistics__list'>
                { props.statistics.statsJson !== null &&
                    props.statistics.statsJson.items.map((item, key) => {
                        return (
                            <li className='statistics__list-item' key={key}>
                                <div className='statistics__number'><h3>{item.number}</h3></div>
                                <div className='statistics__info'>
                                    <h5 className='bold'>{item.title}</h5>
                                    <p>{item.description}</p>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )
}

export default BlockStatistiscs

