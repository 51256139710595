import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import PageHeader from '../components/pageheader'
import CallToAction from '../components/calltoaction'
import ItemLoop from '../components/itemLoop'

const ArchiveNews = ({
    data: {
        page,
        newsItems,
        navigation,
        cta
    },
    pageContext: {
        locale,
    }
}) => {
    return (
        <Layout locale={locale} navigation={navigation} page={page}>
            <PageHeader locale={locale} page={page}/>
            <section className='archive padding'>
                <div className='container'>
                    <ItemLoop data={newsItems} locale={locale}/>
                </div>
            </section>
            <CallToAction cta={cta}/>
        </Layout>
    )
}

export default ArchiveNews

export const query = graphql`
    query( $id: String!, $locale: String) {
         page: contentfulPage( node_locale: { eq: $locale }, id: { eq: $id } ) {
            id
            contentful_id
            node_locale
            pageSlug
            pageType
            pageFullTitle
            pageTitle
            pageSubtitle
            pageVariant
            pageDescription {
                json
            }
            pageImage {
                fluid(quality: 92, maxWidth: 1280) {
                   ...GatsbyContentfulFluid_withWebp
                }
            }
            pageStatistics {
                statsGlobalTitle
                statsGlobalSubtitle
                statsJson {
                    items {
                        title
                        number
                        description
                    }
                }
            }
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }  
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "global"}}) {  
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
        newsItems: allContentfulNieuws(filter: { node_locale: { eq: $locale } }, sort: {order: DESC, fields: createdAt} ) {
            edges {
                node {
                    id
                    contentful_id
                    node_locale
                    createdAt
                    pageTitle
                    pageSubtitle
                    pageDescription {
                        json
                        internal {
                            content
                        }
                    }
                    pageIntroduction {
                        internal {
                            content
                        }
                    }
                    pageImage {
                        fluid(quality: 92, maxWidth: 640, maxHeight: 350) {
                          ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`



